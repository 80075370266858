<template>
  <div>
    <div
      v-if="$route.params.valor == 1"
      class="text-success text-center mt-4"
    >
      <h4 class="text-success">
        <strong> ¡DESCARGA COMPLETADA!</strong>
      </h4>
    </div>
    <div
      v-else
      class="text-danger text-center mt-4"
    >
      <h4 class="text-danger">
        <strong>¡ ERROR AL GENERARSE LA DESCARGA!</strong>
      </h4>
    </div>
  </div>
</template>

<script>

export default {
  mounted() {

  },
}
</script>
